import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { AlertController, ToastController, LoadingController, NavController } from '@ionic/angular';
import { Events } from '../services/events.service';
import { Config } from '../config/config.service';
import * as $ from 'jquery';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "./events.service";
import * as i3 from "../config/config.service";
import * as i4 from "@angular/router";
Date.prototype.toISOString = function () {
    var tzo = -this.getTimezoneOffset(), dif = tzo >= 0 ? '+' : '-', pad = function (num) {
        var norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
    };
    return (this.getFullYear() +
        '-' + pad(this.getMonth() + 1) +
        '-' + pad(this.getDate()) +
        'T' + pad(this.getHours()) +
        ':' + pad(this.getMinutes()) +
        ':' + pad(this.getSeconds()) +
        dif + pad(tzo / 60) +
        ':' + pad(tzo % 60));
};
var Global4Service = /** @class */ (function () {
    function Global4Service(toastController, alertController, loadingController, navCtrl, events, config, router) {
        this.toastController = toastController;
        this.alertController = alertController;
        this.loadingController = loadingController;
        this.navCtrl = navCtrl;
        this.events = events;
        this.config = config;
        this.router = router;
        this.ColXS = 360;
        this.ColSM = 540;
        this.ColMD = 735;
        this.ColLG = 960;
        this.ColXL = 1140;
        this.ColXXL = 1325;
        this.ColXXXL = 1500;
        this.Headers = new HttpHeaders();
        this.Notification = 0;
        this.ParamDashboard = false;
        this.ParamNotification = false;
        this.ParamMyFunction = false;
        this.ParamOption1 = '';
        this.ParamCustoms = [];
        this.SublistErrors = new Array();
        this.Live = false;
        this.Subscriptions = new Array();
        this.Headers.append('Access-Control-Allow-Origin', this.config.SourceURL);
        this.Headers.append('Allow-Control-Allow-Origin', '*');
        this.Headers.append('Access-Control-Allow-Methods', 'POST');
    }
    Global4Service.prototype.SetParam = function (ParamName, Value) {
        sessionStorage.setItem(this.config.ApplicationName + ParamName, Value);
    };
    Global4Service.prototype.GetParam = function (ParamName) {
        return sessionStorage.getItem(this.config.ApplicationName + ParamName);
    };
    Global4Service.prototype.SetSessionUser = function () {
        if (this.CountObject(this.config.User) > 0) {
            for (var config in this.config.User) {
                if (typeof (this.config.User[config]) === 'string') {
                    sessionStorage.setItem(this.config.ApplicationName + config, this.config.User[config]);
                }
                else if (this.InArray(typeof (this.config.User[config]), ['boolean', 'number']) === true) {
                    sessionStorage.setItem(this.config.ApplicationName + config, this.config.User[config].toString());
                }
            }
        }
    };
    Global4Service.prototype.GetSessionUser = function () {
        if (this.CountObject(this.config.User) > 0) {
            for (var config in this.config.User) {
                this.config.SetUserConfig(config, sessionStorage.getItem(this.config.ApplicationName + config));
            }
        }
        // this.ParamID = this.IsUndefined(sessionStorage.getItem(this.config.ApplicationName + 'ParamID'), '');
        this.ParamPage = this.IsUndefined(sessionStorage.getItem(this.config.ApplicationName + 'Page'), '');
        this.ParamEditablePage = this.IsUndefined(sessionStorage.getItem(this.config.ApplicationName + 'EditablePage'), '');
        this.ParamSourcePage = this.IsUndefined(sessionStorage.getItem(this.config.ApplicationName + 'SourcePage'), '');
        this.ParamCurrentPage = this.IsUndefined(sessionStorage.getItem(this.config.ApplicationName + 'CurrentPage'), '');
        this.ParamDashboard = this.IsUndefined(this.ToBoolean(sessionStorage.getItem(this.config.ApplicationName + 'Dashboard')), '');
        this.ParamMyFunction = this.IsUndefined(this.ToBoolean(sessionStorage.getItem(this.config.ApplicationName + 'MyFunction')), '');
        this.ParamNotification = this.IsUndefined(this.ToBoolean(sessionStorage.getItem(this.config.ApplicationName + 'Notification')), '');
        this.ParamOption1 = this.IsUndefined(sessionStorage.getItem(this.config.ApplicationName + 'Option1'), '');
    };
    Global4Service.prototype.GetIP = function () {
        $.getJSON("https://api.ipify.org?format=json", function (data) {
            $('input[name=IPAddress]').val(data.ip);
        });
    };
    Global4Service.prototype.GetSchemaName = function () {
        return (this.config.SchemaName);
    };
    Global4Service.prototype.GetVersion = function () {
        return (this.config.Version);
    };
    Global4Service.prototype.CheckVersion = function () {
        var Loaded = this.ToBoolean(this.IsUndefined(this.GetParam('Loaded'), 'false'));
        var Response = this.Post('', '', 'GetVersion', '', false);
        if (this.IsUndefined(Response, '') !== '') {
            if (Response['Version'][0]['Version'] !== this.config.Version) {
                if (Loaded === false) {
                    this.SetParam('Loaded', 'true');
                    window.location.href = window.location.href;
                }
                else {
                    this.Alert('Warning!', 'You are possibly using a cached version of the system! Please click Ctrl + F5 to refresh your browser. Apologies for the inconvenience.', ['OK']);
                }
            }
            else {
                this.SetParam('Loaded', 'false');
            }
        }
        else {
            this.SetParam('Loaded', 'true');
        }
    };
    Global4Service.prototype.OnDebug = function () {
        return (this.config.Debug);
    };
    Global4Service.prototype.SetUser = function (Config, Value) {
        this.config.User[Config] = Value;
    };
    Global4Service.prototype.GetUser = function (Config) {
        return (this.config.User[Config]);
    };
    Global4Service.prototype.SourceURL = function () {
        if (this.config.Debug === true) {
            return (this.config.LocalURL);
        }
        else {
            return (this.config.SourceURL);
        }
    };
    Global4Service.prototype.URL = function () {
        return (this.config.URL);
    };
    Global4Service.prototype.NotificationURL = function () {
        return (this.config.NotificationURL);
    };
    Global4Service.prototype.UploadURL = function () {
        return (this.config.UploadURL);
    };
    Global4Service.prototype.DeleteURL = function () {
        return (this.config.DeleteURL);
    };
    Global4Service.prototype.DefaultURL = function () {
        return (this.config.DefaultURL);
    };
    Global4Service.prototype.TemplateURL = function () {
        return (this.config.TemplateURL);
    };
    Global4Service.prototype.GetPageSize = function () {
        return (this.config.PageSize);
    };
    Global4Service.prototype.GetEditablePageSize = function () {
        return (this.config.EditablePageSize);
    };
    Global4Service.prototype.ClearUser = function () {
        this.config.UserDefaults();
        this.SetSessionUser();
    };
    Global4Service.prototype.Valid = function (ValidateUser) {
        if (ValidateUser === void 0) { ValidateUser = true; }
        var valid = true;
        this.GetSessionUser();
        var ToRoot = this.GetParam('ToRoot');
        ToRoot = this.IsUndefined(ToRoot, '');
        if (((this.config.User['UsersKey'] === '') ||
            (this.config.User['UsersKey'] === undefined) ||
            (this.config.User['UsersKey'] === null)) &&
            (ValidateUser === true)) {
            valid = false;
            this.Toast('Access denied!', 1500, 'top', 'error');
            this.ClearUser();
            this.navCtrl.navigateRoot('/login');
        }
        else if ((this.Live === false) &&
            (this.router.url !== this.ParamPage)) {
            this.ParamOption1 = '';
            this.SetParam('Option1', '');
            this.Live = true;
            this.Loading('Loading', 0, '', this.ParamPage, 'BACKWARD');
        }
        else {
            this.Live = true;
            this.SetParam('ToRoot', '');
        }
        return (valid);
    };
    Global4Service.prototype.InArray = function (Find, ArrayParameter) {
        if ((ArrayParameter).indexOf(Find) === -1) {
            return (false);
        }
        else {
            return (true);
        }
    };
    Global4Service.prototype.WithModule = function (ModuleName) {
        if (this.config.User['Administrator'] === true) {
            return (true);
        }
        else {
            if (Array.isArray(this.config.User['Modules']) === true) {
                return (this.InArray(ModuleName, this.config.User['Modules']));
            }
            else {
                return (false);
            }
        }
    };
    Global4Service.prototype.WithFunction = function (ModuleName, FunctionName) {
        if (this.config.User['Administrator'] === true) {
            return (true);
        }
        else {
            try {
                return (this.InArray(FunctionName, this.config.User['Rights'][ModuleName]));
            }
            catch (e) {
                return (false);
            }
        }
    };
    Global4Service.prototype.LoadScript = function (URL) {
        $.ajax({
            url: URL,
            dataType: 'script',
            async: false
        });
    };
    Global4Service.prototype.Alert = function (Title, Message, Buttons) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            header: Title,
                            message: Message,
                            buttons: Buttons
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Global4Service.prototype.Toast = function (Message, Duration, Position, Style) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toastController.create({
                            message: Message,
                            duration: Duration,
                            position: Position,
                            cssClass: Style
                        })];
                    case 1:
                        toast = _a.sent();
                        toast.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    Global4Service.prototype.IsUndefined = function (Parameter, Arbitrary) {
        if ((Parameter === undefined) &&
            (Arbitrary === undefined)) {
            return '';
        }
        else if ((Parameter === null) ||
            (Arbitrary === null)) {
            return '';
        }
        else if ((Parameter === undefined) ||
            (Parameter === null)) {
            return Arbitrary;
        }
        else if (typeof (Parameter) === 'string') {
            return (Parameter.trim());
        }
        else {
            return Parameter;
        }
    };
    Global4Service.prototype.SetPrefix = function (Parameter) {
        Parameter = this.IsUndefined(Parameter, '');
        if (Parameter !== '') {
            Parameter = Parameter + ' ';
        }
        return (Parameter);
    };
    Global4Service.prototype.SetPostfix = function (Parameter) {
        Parameter = this.IsUndefined(Parameter, '');
        if (Parameter !== '') {
            Parameter = ' ' + Parameter;
        }
        return (Parameter);
    };
    Global4Service.prototype.Post = function (TransactionURL, Schema, StoredProcedure, Parameter, WithParameter, ClientExecuteOnSuccess, ClientExecuteOnFail, ServerIncludeLibrary, ServerExecuteBefore, ServerExecuteAfter, Async, TransactionType, Publish, Tag, options) {
        if (ClientExecuteOnSuccess === void 0) { ClientExecuteOnSuccess = ''; }
        if (ClientExecuteOnFail === void 0) { ClientExecuteOnFail = ''; }
        if (ServerIncludeLibrary === void 0) { ServerIncludeLibrary = ''; }
        if (ServerExecuteBefore === void 0) { ServerExecuteBefore = ''; }
        if (ServerExecuteAfter === void 0) { ServerExecuteAfter = ''; }
        if (Async === void 0) { Async = false; }
        if (TransactionType === void 0) { TransactionType = 0; }
        if (Publish === void 0) { Publish = ''; }
        if (Tag === void 0) { Tag = ''; }
        if (options === void 0) { options = {}; }
        var Results;
        if (this.IsUndefined(TransactionURL, '') === '') {
            TransactionURL = this.config.URL;
        }
        if (Schema.trim() === '') {
            if (this.config.SchemaName.trim() !== '') {
                Schema = this.config.SchemaName.replace(/ /g, '');
            }
            else {
                Schema = this.config.ApplicationName.replace(/ /g, '');
            }
        }
        var debug = this.config.Debug;
        var events = this.events;
        $.ajax({
            type: 'POST',
            data: 'StoredProcedure=' + encodeURIComponent(Schema + '.' + StoredProcedure.trim()) +
                '&Parameter=' + encodeURIComponent(Parameter.trim()) +
                '&WithParameter=' + encodeURIComponent(WithParameter.toString().trim()) +
                '&ServerIncludeLibrary=' + encodeURIComponent(ServerIncludeLibrary.trim()) +
                '&ServerExecuteBefore=' + encodeURIComponent(ServerExecuteBefore.trim()) +
                '&ServerExecuteAfter=' + encodeURIComponent(ServerExecuteAfter.trim()),
            url: TransactionURL,
            async: Async,
            success: function (Result) {
                if (debug === true) {
                    console.log('>>>> ', Result);
                }
                Results = Result;
                switch (TransactionType) {
                    case 1:
                        events.publish(Publish, {
                            Data: Result
                        });
                        break;
                    case 2:
                        if (Async === true) {
                            events.publish(Publish, {
                                Data: options.Data,
                                Response: Result[Tag][0],
                                CurrentRow: options.Row
                            });
                        }
                        break;
                }
            },
            error: function (err) {
                if (debug === true) {
                    console.log('>>>> err: ', err);
                }
                switch (TransactionType) {
                    case 1:
                        if (debug === true) {
                            console.log('>>>> search failed!');
                        }
                        events.publish(Publish, {
                            Data: null
                        });
                        break;
                    case 2:
                        if ((debug === true) &&
                            (Async === true)) {
                            console.log('>>>> transaction failed!');
                        }
                        break;
                }
            }
        });
        return Results;
    };
    Global4Service.prototype.Transaction = function (ModuleName, StoredProcedure, Parameter, Publish, LoadMessage, FatalErrorMessage, SuccessMessage, UnknownErrorMessage, options, ShowResult, Tag, Async) {
        if (options === void 0) { options = {}; }
        if (ShowResult === void 0) { ShowResult = true; }
        if (Tag === void 0) { Tag = ''; }
        if (Async === void 0) { Async = false; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.loadingController.create({
                    spinner: 'circles',
                    message: LoadMessage,
                    animated: true,
                    translucent: true
                }).then(function (res) {
                    if (_this.config.Debug === true) {
                        console.log('>>>> ' + StoredProcedure);
                        console.log('>>>> ' + Parameter);
                    }
                    var Response = _this.Post('', '', StoredProcedure, Parameter, true, '', '', '', '', '', Async, 2, Publish, Tag, options);
                    if (Async === false) {
                        if (_this.config.Debug === true) {
                            console.log('>>>> ' + JSON.stringify(Response));
                        }
                        if (Tag === '') {
                            Tag = ModuleName;
                        }
                        var Error_1 = true;
                        try {
                            if (Response[Tag][0]['Success'].toString() === '-1') {
                                _this.Toast(FatalErrorMessage, 3000, 'top', 'error');
                            }
                            else if (Response[Tag][0]['Success'].toString() === '0') {
                                _this.SetParam(ModuleName + 'FunctionSuccess', 'true');
                                _this.Toast(Response[Tag][0]['Message'], 3000, 'top', 'error');
                            }
                            else {
                                Error_1 = false;
                                if ((_this.IsUndefined(options.ReturnResult, false) === false) &&
                                    (ShowResult === true)) {
                                    _this.Toast(SuccessMessage, 3000, 'top', 'success');
                                }
                                if (_this.IsUndefined(options.List, false) === true) {
                                    _this.events.publish(Publish, {
                                        Data: _this.IsUndefined(options.Data, []),
                                        Response: Response[Tag][0]
                                    });
                                }
                                else if (_this.IsUndefined(options.ReturnResult, false) === true) {
                                    _this.events.publish(Publish, {
                                        Response: Response
                                    });
                                }
                                else {
                                    _this.events.publish(Publish, {
                                        Response: Response
                                    });
                                }
                            }
                        }
                        catch (e) {
                            _this.Toast(UnknownErrorMessage, 3000, 'top', 'error');
                        }
                        if ((Error_1 === true) &&
                            (_this.IsUndefined(options.ReturnError, false) === true)) {
                            _this.events.publish(Publish, {
                                Response: Response,
                                Options: options.Data,
                                Row: options.Row
                            });
                        }
                    }
                    res.dismiss();
                });
                return [2 /*return*/];
            });
        });
    };
    Global4Service.prototype.Get = function (Function, Tag, StoredProcedure, Parameter, LoadMessage, FatalErrorMessage, UnknownErrorMessage, UnknownFatalErrorMessage, Publish, hotColumnDecode, WithSelector, Editable, Custom) {
        if (hotColumnDecode === void 0) { hotColumnDecode = []; }
        if (WithSelector === void 0) { WithSelector = true; }
        if (Editable === void 0) { Editable = false; }
        if (Custom === void 0) { Custom = []; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var Error, Response;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                Error = false;
                this.loadingController.create({
                    spinner: 'circles',
                    message: LoadMessage,
                    animated: true,
                    translucent: true
                }).then(function (res) {
                    var WithParameter = false;
                    var Customized = {};
                    if (Parameter.trim() !== '') {
                        WithParameter = true;
                    }
                    if (_this.config.Debug === true) {
                        console.log('>>>> ' + StoredProcedure);
                        console.log('>>>> ' + Parameter);
                    }
                    var Async = false;
                    var TransactionType = 0;
                    if (_this.InArray(Function, ['Search', 'GetInfo', 'Initialize']) === true) {
                        Async = true;
                        TransactionType = 1;
                    }
                    Response = _this.Post('', '', StoredProcedure, Parameter, WithParameter, '', '', '', '', '', Async, TransactionType, Publish);
                    if ((_this.config.Debug === true) &&
                        (_this.InArray(Function, ['Search', 'GetInfo', 'Initialize']) === false)) {
                        console.log('>>>> ' + JSON.stringify(Response));
                    }
                    var Success = false;
                    try {
                        if (Response['Success'].toString() === '0' || Response['Success'].toString() === '-1') {
                            _this.Toast(FatalErrorMessage, 3000, 'top', 'error');
                        }
                        else {
                            _this.Toast(UnknownErrorMessage, 3000, 'top', 'error');
                        }
                    }
                    catch (e) {
                        Success = true;
                    }
                    try {
                        Error = !Success;
                    }
                    catch (f) {
                        Error = true;
                    }
                    if (_this.InArray(Function, ['Search', 'GetInfo', 'Initialize']) === false) {
                        if (Error === true) {
                            _this.Toast(UnknownFatalErrorMessage, 3000, 'top', 'error');
                        }
                        else if ((Error === false) &&
                            (Response !== undefined)) {
                            _this.events.publish(Publish, {
                                Data: Response
                            });
                        }
                        else {
                            _this.events.publish(Publish, {
                                Data: []
                            });
                        }
                    }
                    res.dismiss();
                });
                return [2 /*return*/];
            });
        });
    };
    Global4Service.prototype.CheckNotification = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    Global4Service.prototype.Encrypt = function (Parameter) {
        Parameter = this.IsUndefined(Parameter, '');
        if (Parameter !== '') {
            Parameter = btoa(Parameter);
        }
        return Parameter;
    };
    Global4Service.prototype.XMLEncode = function (Parameter) {
        if (this.IsUndefined(Parameter, '') === '') {
            return ('');
        }
        else if (typeof (Parameter) === 'number') {
            return (Parameter);
        }
        else if (typeof (Parameter) === 'string') {
            return (this.IsUndefined(Parameter, '').replace(/\&/g, '&#' + ('&').charCodeAt(0) + ';')
                .replace(/</g, '&#' + ('<').charCodeAt(0) + ';')
                .replace(/>/g, '&#' + ('>').charCodeAt(0) + ';')
                .replace(/\'/g, '&#' + ('\'').charCodeAt(0) + ';')
                .replace(/\"/g, '&#' + ('"').charCodeAt(0) + ';')
                .replace(/\+/g, '&#' + ('+').charCodeAt(0) + ';')
                .replace(/%/g, '&#' + ('%').charCodeAt(0) + ';')
                .replace(/'/g, '&#' + ("'").charCodeAt(0) + ';')
                .replace(/\r/g, '&#xD;')
                .replace(/\n/g, '&#xA;'));
        }
        else {
            return ('');
        }
    };
    Global4Service.prototype.JSONSafe = function (Parameter) {
        if (this.IsUndefined(Parameter, '') === '') {
            return ('');
        }
        else if (typeof (Parameter) === 'number') {
            return (Parameter);
        }
        else if (typeof (Parameter) === 'string') {
            return (this.IsUndefined(Parameter, '').replace(/"/g, '\\"'));
        }
        else {
            return ('');
        }
    };
    Global4Service.prototype.Loading = function (Message, Duration, Class, Page, Direction) {
        var _this = this;
        var loading = this.loadingController.create({
            spinner: 'circles',
            // duration: Duration,
            duration: 1,
            message: Message,
            animated: true,
            translucent: true
        }).then(function (res) {
            res.present();
            if ((Page !== undefined) &&
                (Page !== '')) {
                if (Direction.toUpperCase() === 'ROOT') {
                    _this.navCtrl.navigateRoot(Page);
                }
                else if (Direction.toUpperCase() === 'FORWARD') {
                    _this.navCtrl.navigateForward(Page);
                }
                else if (Direction.toUpperCase() === 'BACKWARD') {
                    _this.navCtrl.navigateBack(Page);
                }
            }
            _this.loadingController.dismiss();
        });
    };
    Global4Service.prototype.Replace = function (StringParameter, Find, ReplaceAs) {
        if (StringParameter !== undefined) {
            var RegExp_1 = eval('/' + Find + '/g');
            return (StringParameter.replace(RegExp_1, ReplaceAs));
        }
        else {
            return ('');
        }
    };
    Global4Service.prototype.ReplaceLast = function (StringParameter, CharacterLength, ReplaceAs) {
        if ((this.IsUndefined(StringParameter, '') !== '') &&
            (CharacterLength > 0) &&
            (this.IsUndefined(ReplaceAs, '') !== '')) {
            var Len = StringParameter.length;
            return (StringParameter.substring(0, Len - CharacterLength) + ReplaceAs);
        }
        else {
            return ('');
        }
    };
    Global4Service.prototype.CopyArray = function (SourceArray) {
        var ReturnArray;
        ReturnArray = new Array();
        if (Array.isArray(SourceArray) === true) {
            for (var index in SourceArray) {
                ReturnArray.push(SourceArray[index]);
            }
        }
        return (ReturnArray);
    };
    Global4Service.prototype.CopyObject = function (SourceObject) {
        var ReturnObject = JSON.parse(JSON.stringify(SourceObject));
        return (ReturnObject);
    };
    Global4Service.prototype.CountObject = function (ObjectParameter) {
        try {
            var Count = 0;
            for (var node in ObjectParameter) {
                Count++;
            }
            return (Count);
        }
        catch (e) {
            return (0);
        }
    };
    Global4Service.prototype.CountArray = function (ArrayParameter) {
        if (Array.isArray(ArrayParameter) === false) {
            return (0);
        }
        else {
            if (ArrayParameter.length > 0) {
                return (1);
            }
            else {
                var Count = 0;
                for (var node in ArrayParameter) {
                    Count++;
                }
                return (Count);
            }
        }
    };
    Global4Service.prototype.InObjects = function (Value, Object, NodeName) {
        if ((Value.trim() !== '') &&
            (this.IsUndefined(Object, '<*>') !== '<*>') &&
            (NodeName.trim() !== '')) {
            if (Object.length > 0) {
                var Found = false;
                var Count = 0;
                while ((Count < Object.length) &&
                    (Found === false)) {
                    if (Value.trim() === Object[Count][NodeName].trim()) {
                        Found = true;
                    }
                    Count++;
                }
                return (Found);
            }
            else {
                return (false);
            }
        }
        else {
            return (false);
        }
    };
    Global4Service.prototype.ToBoolean = function (Input) {
        if ((Input === undefined) ||
            (Input === null)) {
            return (false);
        }
        else {
            switch (Input.toString().toUpperCase()) {
                case '1':
                case 'TRUE':
                    return true;
                    break;
                default:
                    return false;
                    break;
            }
        }
    };
    Global4Service.prototype.isDate = function (value) {
        switch (typeof value) {
            case 'number':
                return true;
            case 'string':
                return !isNaN(Date.parse(value));
            case 'object':
                if (value instanceof Date) {
                    return !isNaN(value.getTime());
                }
            default:
                return false;
        }
    };
    Global4Service.prototype.ToDate = function (DateParam) {
        var DateValue = new Date(DateParam);
        var hoursDiff = DateValue.getHours() - DateValue.getTimezoneOffset() / 60;
        var minutesDiff = (DateValue.getHours() - DateValue.getTimezoneOffset()) % 60;
        DateValue.setHours(hoursDiff);
        DateValue.setMinutes(minutesDiff);
        return (DateValue);
    };
    Global4Service.prototype.DateToString = function (DateParam) {
        if ((DateParam === undefined) ||
            (DateParam === null)) {
            return ('');
        }
        else if (DateParam.trim() === '') {
            return ('');
        }
        else {
            var DateSplit = void 0;
            DateSplit = new Array();
            DateSplit = DateParam.split('T');
            if (this.isDate(DateSplit[0]) === false) {
                return ('');
            }
            else {
                return (DateSplit[0]);
            }
        }
    };
    Global4Service.prototype.toISOString = function (_Date) {
        if (_Date === null) {
            return (null);
        }
        else {
            return (_Date.toISOString());
        }
    };
    Global4Service.prototype.DateTimeToString = function (DateTimeParam) {
        if (this.IsUndefined(DateTimeParam, '').trim() === '') {
            return ('');
        }
        else {
            var DateTimeVar = void 0;
            DateTimeVar = new Array();
            DateTimeVar = DateTimeParam.split('+');
            if (DateTimeVar.length > 1) {
                var DateTimeVar2 = void 0;
                DateTimeVar2 = DateTimeVar[0].split('T');
                if (DateTimeVar2.length > 1) {
                    var DateTimeVar3 = void 0;
                    DateTimeVar3 = DateTimeVar2[1].split(':');
                    if (DateTimeVar3.length > 1) {
                        return (DateTimeVar2[0].replace('T', ' ')
                            .replace('Z', ' ') + ' ' + DateTimeVar3[0] + ':' + DateTimeVar3[1]);
                    }
                    else {
                        return (DateTimeParam);
                    }
                }
                else {
                    return (DateTimeParam);
                }
            }
            else {
                return (DateTimeParam);
            }
        }
    };
    Global4Service.prototype.TimeToString = function (TimeParam) {
        if ((TimeParam.trim() === '') ||
            (TimeParam === undefined) ||
            (TimeParam === null)) {
            return ('');
        }
        else {
            var TimeVar = void 0;
            TimeVar = new Array();
            TimeVar = TimeParam.split('+');
            return (TimeVar[0].replace('Z', ' '));
        }
    };
    Global4Service.prototype.DateTimeToTime = function (DateTimeParam) {
        if ((DateTimeParam.trim() === '') ||
            (DateTimeParam === undefined) ||
            (DateTimeParam === null)) {
            return ('');
        }
        else {
            var DateTimeVar = void 0;
            DateTimeVar = new Array();
            DateTimeVar = DateTimeParam.split('T');
            var TimeZoneVar = void 0;
            TimeZoneVar = new Array();
            TimeZoneVar = DateTimeVar[1].split('+');
            var TimeParts = void 0;
            TimeParts = new Array();
            TimeParts = TimeZoneVar[0].replace('T', ' ')
                .replace('Z', ' ')
                .split(':');
            var Hour = parseInt(TimeParts[0], 10);
            var AMPM = void 0;
            if (Hour - 12 > 0) {
                Hour = Hour - 12;
                AMPM = 'PM';
            }
            else {
                AMPM = 'AM';
            }
            return (Hour.toString() + ':' + TimeParts[1] + ' ' + AMPM);
        }
    };
    Global4Service.prototype.ConvertMMDDYYYY = function (DateString, Delimeter, ConvertFormat) {
        var DateArr = DateString.split(Delimeter);
        return (ConvertFormat.replace(/YYYY/g, DateArr[2])
            .replace(/MM/g, DateArr[0])
            .replace(/DD/g, DateArr[1]));
    };
    Global4Service.prototype.MonthDiff = function (Date1, Date2) {
        var Months;
        Months = (Date2.getFullYear() - Date1.getFullYear()) * 12;
        Months -= Date1.getMonth();
        Months += Date2.getMonth();
        return (Months);
    };
    Global4Service.prototype.decodeString = function (str) {
        if ((str !== null) &&
            (str !== undefined)) {
            return str.replace(/&#(\d+);/g, function (match, dec) {
                return String.fromCharCode(dec);
            });
        }
        else {
            return ('');
        }
    };
    Global4Service.prototype.decodeHtmlEntity = function (str) {
        if ((str === null) ||
            (str === undefined)) {
            return ('');
        }
        else {
            str = str.toString();
            return (this.decodeString(str).replace(/\\\\/g, '')
                .replace(/\\&#10;/g, '\\n'));
        }
    };
    Global4Service.prototype.DecodeArray = function (Arr) {
        var ReturnArr = new Array();
        if (this.CountArray(Arr) > 0) {
            for (var x = 0; x < Arr.length - 1; x++) {
                ReturnArr.push(this.decodeHtmlEntity(Arr[x]));
            }
        }
        return (ReturnArr);
    };
    Global4Service.prototype.DecodeDropDowns = function (Options, Objects) {
        var Returns = new Array();
        if ((this.CountObject(Options) > 0) &&
            (this.CountObject(Objects) > 0)) {
            for (var object in Objects) {
                Returns[object] = new Array();
                try {
                    if (this.IsUndefined(Objects[object]['Node'], '') === '') {
                        for (var y = 0; y < Options[object].length; y++) {
                            Returns[object].push(this.decodeHtmlEntity(Options[object][y]));
                        }
                    }
                    else {
                        for (var y = 0; y < Options[object].length; y++) {
                            Returns[object].push(this.decodeHtmlEntity(Options[object][y][Objects[object]['Node']]));
                        }
                    }
                }
                catch (e) {
                    Returns[object] = new Array();
                }
            }
        }
        return (Returns);
    };
    Global4Service.prototype.GenerateCode = function (___Length) {
        ___Length = this.IsUndefined(___Length, 10);
        var __Code = '';
        var __Chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (var i = 0; i < ___Length; i++) {
            __Code += __Chars.charAt(Math.floor(Math.random() * __Chars.length));
        }
        return (__Code);
    };
    Global4Service.prototype.GetTodayString = function () {
        var today = new Date();
        var Month = (today.getMonth() + 1).toString();
        if (Month.length === 1) {
            Month = '0' + Month;
        }
        var date = today.getFullYear() + '-' + Month + '-' + ('0').repeat(2 - today.getDate().toString().length) + today.getDate();
        return (date);
    };
    Global4Service.prototype.GetTodayTimeString = function () {
        var today = new Date();
        var Month = (today.getMonth() + 1).toString();
        if (Month.length === 1) {
            Month = '0' + Month;
        }
        var date = today.getFullYear() + '-' + Month + '-' + ('0').repeat(2 - today.getDate().toString().length) + today.getDate();
        var time = today.getHours() + ':' + ('0').repeat(2 - today.getMinutes().toString().length) + today.getMinutes();
        return (date + ' ' + time);
    };
    Global4Service.prototype.SetDateTime = function (DateTimeString) {
        var _arrDate;
        _arrDate = DateTimeString.replace(/T/g, ' ')
            .replace(/Z/g, '')
            .split(' ');
        var _dayParts;
        _dayParts = this.GetDateParts(_arrDate[0]);
        if (_arrDate.length === 1) {
            return (_dayParts['Year'].toString() + '-' + _dayParts['Month'].toString() + '-' + _dayParts['Day'].toString());
        }
        else {
            var _timeParts = void 0;
            _timeParts = this.GetTimeParts(_arrDate[1]);
            return (_dayParts['Year'].toString() + '-' + _dayParts['Month'].toString() + '-' + _dayParts['Day'].toString() + ' ' + _timeParts['Hour'].toString() + ':' + _timeParts['Minute'] + ' ' + _timeParts['AMPM']);
        }
    };
    Global4Service.prototype.toFixed = function (x) {
        if (Math.abs(x) < 1.0) {
            var e = parseInt(x.toString().split('e-')[1], 10);
            if (e) {
                x *= Math.pow(10, e - 1);
                x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
            }
        }
        else {
            var f = parseInt(x.toString().split('+')[1], 10);
            if (f > 20) {
                f -= 20;
                x /= Math.pow(10, f);
                x += (new Array(f + 1)).join('0');
            }
        }
        return (x);
    };
    Global4Service.prototype.ParseFloat = function (number, AllowNegative) {
        if (AllowNegative === void 0) { AllowNegative = true; }
        if (typeof (number) === 'string') {
            if (this.IsUndefined(number, '') !== '') {
                var returnStr = void 0;
                returnStr = this.IsUndefined(number, '0').toString();
                returnStr = returnStr.replace(/,/g, '');
                if ((parseFloat(returnStr) < 0) &&
                    (AllowNegative === false)) {
                    return (0);
                }
                else {
                    return (parseFloat(returnStr));
                }
            }
            else {
                return (0);
            }
        }
        else if (typeof (number) === 'number') {
            return (number);
        }
        else {
            return (0);
        }
    };
    Global4Service.prototype.CheckInteger = function (___NumParam, ___ArbitraryValue, ___AllowNegative) {
        if (___AllowNegative === void 0) { ___AllowNegative = true; }
        if ((___NumParam == null) ||
            (typeof (___NumParam) === undefined) ||
            (parseInt(___NumParam, 10).toString() === 'NaN')) {
            return (this.IsUndefined(___ArbitraryValue, 0));
        }
        else {
            var ReturnNumber = void 0;
            ReturnNumber = parseInt(___NumParam, 10);
            if ((___AllowNegative === false) &&
                (ReturnNumber < 0)) {
                ReturnNumber = this.IsUndefined(___ArbitraryValue, 0);
            }
            return (ReturnNumber);
        }
    };
    Global4Service.prototype.CheckDate = function (DateParam) {
        if (DateParam.trim() === '') {
            return (null);
        }
        else {
            return (new Date(DateParam));
        }
    };
    Global4Service.prototype.DateCompute = function (DateParam, DatePart, Count) {
        if (DateParam === null) {
            return (null);
        }
        else {
            switch (DatePart.toUpperCase()) {
                case 'DAY':
                    DateParam.setDate(DateParam.getDate() + Count);
                    break;
            }
            return (DateParam);
        }
    };
    Global4Service.prototype.GetTimeParts = function (___Time) {
        var __TimeParts;
        var __TimeSplit;
        __TimeSplit = ___Time.split(':');
        __TimeParts = new Array();
        if (__TimeSplit.length === 2) {
            __TimeParts['Hour'] = __TimeSplit[0];
            __TimeParts['Minute'] = __TimeSplit[1].substr(0, 2);
            __TimeParts['AMPM'] = __TimeSplit[1].substr(2, 2);
        }
        else {
            if (parseInt(__TimeSplit[0], 10) < 12) {
                __TimeParts['Hour'] = __TimeSplit[0];
                __TimeParts['AMPM'] = 'AM';
            }
            else {
                __TimeParts['AMPM'] = 'PM';
                if (parseInt(__TimeSplit[0], 10) > 12) {
                    __TimeParts['Hour'] = (parseInt(__TimeSplit[0], 10) - 12).toString();
                }
            }
            if (parseInt(__TimeSplit[0], 10) === 0) {
                __TimeParts['Hour'] = '12';
            }
            __TimeParts['Minute'] = __TimeSplit[1];
        }
        var __Hour = __TimeParts['Hour'];
        var __Minute = __TimeParts['Minute'];
        var __AMPM = __TimeParts['AMPM'];
        var __Validated = true;
        if (((this.CheckInteger(__Hour, 0) < 1) ||
            (this.CheckInteger(__Hour, 0) > 12) ||
            (this.CheckInteger(__Minute, 0) < 0) ||
            (this.CheckInteger(__Minute, 0) > 59)) &&
            ((__AMPM !== 'AM') &&
                (__AMPM !== 'PM'))) {
            __Validated = false;
        }
        if (__Validated === true) {
            return (__TimeParts);
        }
        else {
            return (null);
        }
    };
    Global4Service.prototype.GetDateTimeParts = function (___DateTime) {
        var __DateParts;
        __DateParts = ___DateTime.split('T');
        var __Date;
        __Date = this.GetDateParts(__DateParts[0]);
        var ___Time;
        ___Time = __DateParts[1];
        var __TimeParts;
        var __TimeSplit;
        __TimeSplit = ___Time.split(':');
        __TimeParts = new Array();
        __TimeParts['Year'] = __Date['Year'];
        __TimeParts['Month'] = __Date['Month'];
        __TimeParts['Day'] = __Date['Day'];
        if (__TimeSplit.length === 2) {
            __TimeParts['Hour'] = __TimeSplit[0];
            __TimeParts['Minute'] = __TimeSplit[1].substr(0, 2);
            __TimeParts['AMPM'] = __TimeSplit[1].substr(2, 2);
        }
        else {
            if (parseInt(__TimeSplit[0], 10) < 12) {
                __TimeParts['Hour'] = __TimeSplit[0];
                __TimeParts['AMPM'] = 'AM';
            }
            else {
                __TimeParts['AMPM'] = 'PM';
                if (parseInt(__TimeSplit[0], 10) > 12) {
                    __TimeParts['Hour'] = (parseInt(__TimeSplit[0], 10) - 12).toString();
                }
            }
            if (parseInt(__TimeSplit[0], 10) === 0) {
                __TimeParts['Hour'] = '12';
            }
            __TimeParts['Minute'] = __TimeSplit[1];
        }
        var __Hour = __TimeParts['Hour'];
        var __Minute = __TimeParts['Minute'];
        var __AMPM = __TimeParts['AMPM'];
        var __Validated = true;
        if (((this.CheckInteger(__Hour, 0) < 1) ||
            (this.CheckInteger(__Hour, 0) > 12) ||
            (this.CheckInteger(__Minute, 0) < 0) ||
            (this.CheckInteger(__Minute, 0) > 59)) &&
            ((__AMPM !== 'AM') &&
                (__AMPM !== 'PM'))) {
            __Validated = false;
        }
        if (__Validated === true) {
            return (__TimeParts);
        }
        else {
            return (null);
        }
    };
    Global4Service.prototype.GetDateParts = function (___Date) {
        var __DateParts;
        __DateParts = new Array();
        if (___Date.length === 10) {
            __DateParts['Year'] = ___Date.substr(0, 4);
            __DateParts['Month'] = ___Date.substr(5, 2);
            __DateParts['Day'] = ___Date.substr(8, 2);
        }
        else {
            var _arrDate = void 0;
            _arrDate = ___Date.split('-');
            __DateParts['Year'] = _arrDate[0];
            __DateParts['Month'] = _arrDate[1];
            __DateParts['Day'] = _arrDate[2];
        }
        /* copy of ValidateDateParts */
        /* NOTE : cannot use ValidateDateParts as it will become a circular function */
        var __Year = this.CheckInteger(__DateParts['Year'], 0);
        var __Month = this.CheckInteger(__DateParts['Month'], 0);
        var __Day = this.CheckInteger(__DateParts['Day'], 0);
        var __Validated = false;
        if (((__Year >= 1000) &&
            (__Year <= 9999)) &&
            ((((__Month === 1) ||
                (__Month === 3) ||
                (__Month === 5) ||
                (__Month === 7) ||
                (__Month === 8) ||
                (__Month === 10) ||
                (__Month === 12)) &&
                ((__Day >= 1) &&
                    (__Day <= 31))) ||
                (((__Month === 4) ||
                    (__Month === 6) ||
                    (__Month === 9) ||
                    (__Month === 11)) &&
                    ((__Day >= 1) &&
                        (__Day <= 30))) ||
                ((__Month === 2) &&
                    (((__Year % 4 === 0) &&
                        ((__Day >= 1) &&
                            (__Day <= 29))) ||
                        ((__Year % 4 !== 0) &&
                            ((__Day >= 1) &&
                                (__Day <= 28))))))) {
            __Validated = true;
        }
        if (__Validated === true) {
            return (__DateParts);
        }
        else {
            return (null);
        }
    };
    Global4Service.prototype.ValidateTime = function (___Time) {
        if (___Time.trim() !== '') {
            var __TimeParts = void 0;
            __TimeParts = this.GetTimeParts(___Time);
            if ((__TimeParts === null) ||
                (Array.isArray(__TimeParts) === false)) {
                return (false);
            }
            else {
                return (true);
            }
        }
        else {
            return (false);
        }
    };
    Global4Service.prototype.ValidateDate = function (___Date) {
        if (___Date.trim() !== '') {
            var __DateParts = void 0;
            __DateParts = this.GetDateParts(___Date);
            if ((__DateParts == null) ||
                (Array.isArray(__DateParts) === false)) {
                return (false);
            }
            else {
                if ((___Date.substr(4, 1) === '-') &&
                    (___Date.substr(7, 1) === '-')) {
                    return (true);
                }
                else {
                    return (false);
                }
            }
        }
        else {
            return (false);
        }
    };
    Global4Service.prototype.GetMilitaryTime = function (___Time) {
        var __Return = '';
        var __TimeParts;
        __TimeParts = this.GetTimeParts(___Time);
        if (__TimeParts != null) {
            if ((__TimeParts['AMPM'] === 'AM') &&
                (this.CheckInteger(__TimeParts['Hour'], 0) === 12)) {
                __TimeParts['Hour'] = '00';
            }
            else if ((__TimeParts['AMPM'] === 'PM') &&
                (this.CheckInteger(__TimeParts['Hour'], 0) > 12)) {
                __TimeParts['Hour'] = (this.CheckInteger(__TimeParts['Hour'], 0) + 12).toString();
            }
            __Return = __TimeParts['Hour'] + ':' + __TimeParts['Minute'] + ':00';
            return (__Return);
        }
        return (__Return);
    };
    Global4Service.prototype.CompareTime = function (___Time1, ___Time2) {
        /*
            -1        - one or more invalid
            0         - equal
            1         - first greater than second
            2         - second greater than first
        */
        var __Compare = -1;
        if ((this.ValidateTime(___Time1) === true) &&
            (this.ValidateTime(___Time2) === true)) {
            var __Time1 = '1900-01-01 ' + this.GetMilitaryTime(___Time1);
            var __Time2 = '1900-01-01 ' + this.GetMilitaryTime(___Time2);
            if (__Time1 === __Time2) {
                __Compare = 0;
            }
            else if (__Time1 > __Time2) {
                __Compare = 1;
            }
            else if (__Time1 < __Time2) {
                __Compare = 2;
            }
        }
        return (__Compare);
    };
    Global4Service.prototype.ComputeTime = function (___TimeInput, ___TimePart, ___Interval) {
        var __Return = ___TimeInput;
        if ((this.ValidateTime(___TimeInput) === true) &&
            ((___TimePart === 'Hour') ||
                (___TimePart === 'Minute')) &&
            (typeof (___Interval) === 'number')) {
            ___Interval = this.CheckInteger(___Interval, 0);
            if (___Interval !== 0) {
                __Return = '';
                var __TimeParts = void 0;
                __TimeParts = this.GetTimeParts(___TimeInput);
                var __HourInterval = 0;
                var __Hour = this.CheckInteger(__TimeParts['Hour'], 0);
                var __Minute = this.CheckInteger(__TimeParts['Minute'], 0);
                if (___TimePart === 'Hour') {
                    __HourInterval = ___Interval;
                }
                if (___TimePart === 'Minute') {
                    __Minute = this.CheckInteger(__TimeParts['Minute'], 0) + ___Interval;
                    __HourInterval = Math.floor(__Minute / 60);
                    __Minute = __Minute % 60;
                    if (__Minute < 0) {
                        __HourInterval = __HourInterval - 1;
                        __Minute = 60 - Math.abs(__Minute);
                    }
                    if (__HourInterval !== 0) {
                        ___TimePart = 'Hour';
                    }
                }
                if ((___TimePart === 'Hour') &&
                    (__HourInterval !== 0)) {
                    if ((__TimeParts['AMPM'] === 'AM') &&
                        (this.CheckInteger(__Hour, 0) === 12)) {
                        __Hour = 0;
                    }
                    else if ((__TimeParts['AMPM'] === 'PM') &&
                        (__Hour > 12)) {
                        __Hour = __Hour + 12;
                    }
                    __Hour = __Hour + __HourInterval;
                    __Hour = __Hour % 24;
                    if (__Hour < 0) {
                        __Hour = 24 - Math.abs(__Hour);
                    }
                    if (__Hour >= 12) {
                        __TimeParts['AMPM'] = 'PM';
                    }
                    else {
                        __TimeParts['AMPM'] = 'AM';
                    }
                    if (__Hour >= 12) {
                        __Hour = __Hour - 12;
                    }
                    if (__Hour === 0) {
                        __Hour = 12;
                    }
                }
                if (__Hour.toString().length === 1) {
                    __Return += '0' + __Hour.toString();
                }
                else if (__Hour === 0) {
                    __Return += '12';
                }
                else {
                    __Return += __Hour.toString();
                }
                __Return += ':';
                if (__Minute.toString().length === 1) {
                    __Return += '0' + __Minute.toString();
                }
                else {
                    __Return += __Minute.toString();
                }
                __Return += __TimeParts['AMPM'];
            }
        }
        return (__Return);
    };
    Global4Service.prototype.ComputeDateTime = function (___TimeInput, ___TimePart, ___Interval) {
        var __Return = ___TimeInput;
        if ((this.ValidateTime(___TimeInput) === true) &&
            ((___TimePart === 'Hour') ||
                (___TimePart === 'Minute')) &&
            (typeof (___Interval) === 'number')) {
            ___Interval = this.CheckInteger(___Interval, 0);
            if (___Interval !== 0) {
                __Return = '';
                var __TimeParts = void 0;
                __TimeParts = this.GetDateTimeParts(___TimeInput);
                var __HourInterval = 0;
                var __Hour = this.CheckInteger(__TimeParts['Hour'], 0);
                var __Minute = this.CheckInteger(__TimeParts['Minute'], 0);
                if (___TimePart === 'Hour') {
                    __HourInterval = ___Interval;
                }
                if (___TimePart === 'Minute') {
                    __Minute = this.CheckInteger(__TimeParts['Minute'], 0) + ___Interval;
                    __HourInterval = Math.floor(__Minute / 60);
                    __Minute = __Minute % 60;
                    if (__Minute < 0) {
                        __HourInterval = __HourInterval - 1;
                        __Minute = 60 - Math.abs(__Minute);
                    }
                    if (__HourInterval !== 0) {
                        ___TimePart = 'Hour';
                    }
                }
                if ((___TimePart === 'Hour') &&
                    (__HourInterval !== 0)) {
                    if ((__TimeParts['AMPM'] === 'AM') &&
                        (this.CheckInteger(__Hour, 0) === 12)) {
                        __Hour = 0;
                    }
                    else if ((__TimeParts['AMPM'] === 'PM') &&
                        (__Hour > 12)) {
                        __Hour = __Hour + 12;
                    }
                    __Hour = __Hour + __HourInterval;
                    __Hour = __Hour % 24;
                    if (__Hour < 0) {
                        __Hour = 24 - Math.abs(__Hour);
                    }
                    if (__Hour >= 12) {
                        __Hour = __Hour - 12;
                    }
                    if (__Hour === 0) {
                        __Hour = 12;
                    }
                }
                if (__Hour.toString().length === 1) {
                    __Return += '0' + __Hour.toString();
                }
                else if (__Hour === 0) {
                    __Return += '12';
                }
                else {
                    __Return += __Hour.toString();
                }
                __Return += ':';
                if (__Minute.toString().length === 1) {
                    __Return += '0' + __Minute.toString();
                }
                else {
                    __Return += __Minute.toString();
                }
                __Return += __TimeParts['AMPM'];
                __Return = __TimeParts['Year'] + '-' + __TimeParts['Month'] + '-' + __TimeParts['Day'] + ' ' + __Return;
            }
        }
        return (__Return);
    };
    Global4Service.prototype.CompareDate = function (DateStr1, DateStr2) {
        /*
            -10    - both blanks
            -11    - first blank
            -12    - second blank
            0      - equal
            1      - first greater than second
            -1     - second greater than first
        */
        if ((DateStr1.trim() === '') &&
            (DateStr2.trim() === '')) {
            return (-10);
        }
        else if (DateStr1.trim() === '') {
            return (-11);
        }
        else if (DateStr2.trim() === '') {
            return (-12);
        }
        else {
            var DateInt1 = Date.parse(DateStr1.substr(0, 10));
            var DateInt2 = Date.parse(DateStr2.substr(0, 10));
            if (DateInt1 === DateInt2) {
                return (0);
            }
            else if (DateInt1 > DateInt2) {
                return (1);
            }
            else if (DateInt1 < DateInt2) {
                return (-1);
            }
        }
    };
    Global4Service.prototype.CompareDateTime = function (DateStr1, DateStr2) {
        /*
            -10    - both blanks
            -11    - first blank
            -12    - second blank
            0      - equal
            1      - first greater than second
            -1     - second greater than first
        */
        if ((DateStr1.trim() === '') &&
            (DateStr2.trim() === '')) {
            return (-10);
        }
        else if (DateStr1.trim() === '') {
            return (-11);
        }
        else if (DateStr2.trim() === '') {
            return (-12);
        }
        else {
            var DateInt1 = Date.parse(DateStr1);
            var DateInt2 = Date.parse(DateStr2);
            if (DateInt1 === DateInt2) {
                return (0);
            }
            else if (DateInt1 > DateInt2) {
                return (1);
            }
            else if (DateInt1 < DateInt2) {
                return (-1);
            }
        }
    };
    Global4Service.prototype.PhoneFormat = function (Phone) {
        if (Phone.trim() === '') {
            return ('');
        }
        else {
            return ('(' + Phone.substr(0, 2) + ') ' + Phone.substr(2, 4) + '-' + Phone.substr(6, 4));
        }
    };
    Global4Service.prototype.TitleCase = function (Param) {
        if (Param.trim() !== '') {
            var Sentence = Param.toLowerCase().split(' ');
            for (var i = 0; i < Sentence.length; i++) {
                Sentence[i] = this.IsUndefined(Sentence[i][0], '').toUpperCase() + Sentence[i].slice(1);
            }
            var Return = Sentence.join(' ');
            return (Return);
        }
        else {
            return ('');
        }
    };
    Global4Service.prototype.NameCase = function (Param) {
        var Sentence = Param.split(' ');
        for (var i = 0; i < Sentence.length; i++) {
            Sentence[i] = this.IsUndefined(Sentence[i][0], '').toUpperCase() + Sentence[i].slice(1);
        }
        var Return = Sentence.join(' ');
        return (Return);
    };
    Global4Service.prototype.ToISOTime = function (DateParam) {
        var Time = this.IsUndefined(DateParam.toISOString().split('T')[1], '');
        return (Time);
    };
    Global4Service.prototype.DisplayDate = function (DateParam) {
        if (this.IsUndefined(DateParam, '') !== '') {
            if (Date.parse(DateParam) === NaN) {
                return ('');
            }
            else {
                return (DateParam.split('T')[0]);
            }
        }
        else {
            return ('');
        }
    };
    Global4Service.prototype.DisplayDateTime = function (_Date, WithTime) {
        if (WithTime === void 0) { WithTime = false; }
        try {
            var _DateString = _Date.toISOString().split('T')[0];
            var _TimeString = '';
            var __DateParts = void 0;
            __DateParts = new Array();
            if (_DateString.length === 10) {
                __DateParts['Year'] = _DateString.substr(0, 4);
                __DateParts['Month'] = _DateString.substr(5, 2);
                __DateParts['Day'] = _DateString.substr(8, 2);
            }
            else {
                var _arrDate = void 0;
                _arrDate = _DateString.split('-');
                __DateParts['Year'] = _arrDate[0];
                __DateParts['Month'] = _arrDate[1];
                __DateParts['Day'] = _arrDate[2];
            }
            if (WithTime) {
                _TimeString = _Date.toISOString().split('T')[1].split('+')[0];
            }
            return ((__DateParts['Month'] + '/' + __DateParts['Day'] + '/' + __DateParts['Year'] + ' ' + _TimeString).trim());
        }
        catch (e) {
            return ('');
        }
    };
    Global4Service.prototype.DisplayTime = function (_Date) {
        try {
            return (this.DateTimeToTime(_Date.toISOString()));
        }
        catch (e) {
            return ('');
        }
    };
    Global4Service.prototype.CheckSubscription = function (GUID, Function) {
        var Exists = true;
        if (this.InArray(GUID, this.Subscriptions) === false) {
            this.Subscriptions.push(GUID);
            this.Subscriptions[GUID] = new Array();
            this.Subscriptions[GUID].push(Function.toLowerCase().trim());
            Exists = false;
        }
        else {
            if (this.InArray(Function.toLowerCase().trim(), this.Subscriptions[GUID]) === false) {
                this.Subscriptions[GUID].push(Function.toLowerCase().trim());
                Exists = false;
            }
        }
        return (Exists);
    };
    Global4Service.prototype.Unsubscribes = function (GUID) {
        var Subscriptions = this.CopyObject(this.Subscriptions[GUID]);
        if (Subscriptions.length > 0) {
            for (var Function_1 in Subscriptions) {
                this.events.destroy(GUID.toLowerCase().substring(0, GUID.length - 4) + Subscriptions[Function_1]);
                this.Subscriptions[GUID].splice(Function_1);
            }
        }
    };
    Global4Service.prototype.WithSelected = function (Data, SelectorColumn) {
        if (SelectorColumn === void 0) { SelectorColumn = 'Selector'; }
        if (this.CountArray(Data) > 0) {
            var Count = 0;
            var Selected = false;
            while ((Count < Data.length) &&
                (Selected === false)) {
                if (this.ToBoolean(Data[Count][SelectorColumn]) === true) {
                    Selected = true;
                }
                else {
                    Count++;
                }
            }
            return (Selected);
        }
        else {
            return false;
        }
    };
    Global4Service.prototype.GetSelectedIDs = function (Data, IDColumn, SelectorColumn, Splitter) {
        if (SelectorColumn === void 0) { SelectorColumn = 'Selector'; }
        if (Splitter === void 0) { Splitter = '|'; }
        var ReturnIDs = '';
        if ((this.CountArray(Data) > 0) &&
            (IDColumn.trim() !== '')) {
            var Count = 0;
            while (Count < Data.length) {
                if (this.ToBoolean(Data[Count][SelectorColumn]) === true) {
                    ReturnIDs = ReturnIDs + Data[Count][IDColumn] + Splitter;
                }
                Count++;
            }
        }
        return (ReturnIDs);
    };
    Global4Service.prototype.NumericAddCommas = function (___Val) {
        ___Val += '';
        var ___Arr = ___Val.split('.');
        var ___Exp1 = ___Arr[0];
        var ___Exp2 = ___Arr.length > 1 ? '.' + ___Arr[1] : '';
        var __RegExpr = /(\d+)(\d{3})/;
        while (__RegExpr.test(___Exp1)) {
            ___Exp1 = ___Exp1.replace(__RegExpr, '$1' + ',' + '$2');
        }
        return (___Exp1 + ___Exp2);
    };
    Global4Service.prototype.MoneyFormat = function (___Val) {
        switch (typeof (___Val)) {
            case 'string':
                ___Val = this.ParseFloat(___Val).toString();
                break;
            case 'number':
                ___Val = ___Val.toString();
                break;
        }
        if (___Val === null) {
            ___Val = 0;
        }
        ___Val = this.NumericAddCommas(___Val);
        var __ValSplit = ___Val.split('.');
        if (__ValSplit.length < 2) {
            ___Val += '.00';
        }
        else {
            if (__ValSplit[1].length < 2) {
                for (var i = 2 - __ValSplit[1].length; i < 2; i++) {
                    __ValSplit[1] += '0';
                }
            }
            else {
                __ValSplit[1] = __ValSplit[1].substr(0, 2);
            }
            ___Val = __ValSplit[0] + '.' + __ValSplit[1];
        }
        return (___Val);
    };
    Global4Service.prototype.ToISOString = function (_date) {
        if (_date === null) {
            return ('');
        }
        else {
            var tzo = -_date.getTimezoneOffset(), dif = tzo >= 0 ? '+' : '-', pad = function (num) {
                var norm = Math.floor(Math.abs(num));
                return (norm < 10 ? '0' : '') + norm;
            };
            return _date.getFullYear() +
                '-' + pad(_date.getMonth() + 1) +
                '-' + pad(_date.getDate()) +
                'T' + pad(_date.getHours()) +
                ':' + pad(_date.getMinutes()) +
                ':' + pad(_date.getSeconds()) +
                dif + pad(tzo / 60) +
                ':' + pad(tzo % 60);
        }
    };
    Global4Service.prototype.ToJSString = function (_date) {
        if (this.IsUndefined(_date, '') === '') {
            return (null);
        }
        else {
            var __Year = _date.substr(0, 4);
            var __Month = void 0;
            switch (parseInt(_date.substr(5, 2), 10)) {
                case 1:
                    __Month = 'Jan';
                    break;
                case 2:
                    __Month = 'Feb';
                    break;
                case 3:
                    __Month = 'Mar';
                    break;
                case 4:
                    __Month = 'Apr';
                    break;
                case 5:
                    __Month = 'May';
                    break;
                case 6:
                    __Month = 'Jun';
                    break;
                case 7:
                    __Month = 'Jul';
                    break;
                case 8:
                    __Month = 'Aug';
                    break;
                case 9:
                    __Month = 'Sep';
                    break;
                case 10:
                    __Month = 'Oct';
                    break;
                case 11:
                    __Month = 'Nov';
                    break;
                case 12:
                    __Month = 'Dec';
                    break;
            }
            var __Day = _date.substr(8, 2);
            var __Hour = _date.substr(11, 2);
            var __Minute = _date.substr(14, 2);
            var __AMPM = _date.substr(16, 2);
            if (__AMPM === 'PM') {
                __Hour = (parseInt(__Hour, 10) + 12).toString();
            }
            return (__Day + ' ' + __Month + ' ' + __Year + ' ' + __Hour + ':' + __Minute + ':00 GMT');
        }
    };
    Global4Service.prototype.CheckYear = function (_Year) {
        if (isNaN(parseInt(_Year, 10))) {
            return ('');
        }
        else if (parseInt(_Year, 10) < 1900) {
            return ('');
        }
        else if (parseInt(_Year, 10) > 9999) {
            return ('');
        }
        else {
            return (_Year);
        }
    };
    Global4Service.prototype.IsPrintableCharacter = function (KeyCode) {
        if ((KeyCode > 47 && KeyCode < 58) ||
            (KeyCode == 32 || KeyCode == 13) ||
            (KeyCode > 64 && KeyCode < 91) ||
            (KeyCode > 95 && KeyCode < 112) ||
            (KeyCode > 185 && KeyCode < 193) ||
            (KeyCode > 218 && KeyCode < 223)) {
            return (true);
        }
        else {
            return (false);
        }
    };
    Global4Service.prototype.SetSublistError = function (ParentModule, ModuleName, ErrorType, Error) {
        if (Array.isArray(this.SublistErrors[ParentModule + ModuleName]) === false) {
            this.SublistErrors[ParentModule + ModuleName] = new Array();
        }
        this.SublistErrors[ParentModule + ModuleName][ErrorType] = Error;
    };
    // ConsoleLog(Message: any) {
    //   if (this.config.Debug) {
    //     console.log('>> ', Message);
    //   }
    // }
    Global4Service.prototype.encodeImage = function (imageURL) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var blob, imgData, file;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(imageURL).then(function (r) { return r.blob(); }).then(function (blobFile) { return new File([blobFile], imageURL, { type: 'image/jpg' }); })];
                    case 1:
                        blob = _a.sent();
                        file = new FileReader();
                        file.onloadend = function () {
                            imgData = file.result;
                        };
                        file.readAsDataURL(blob);
                        return [2 /*return*/, (imgData)];
                }
            });
        });
    };
    Global4Service.prototype.Delay = function (Time) {
        return (new Promise(function (resolve) { return setTimeout(resolve, Time); }));
    };
    Global4Service.prototype.DeleteAttachment = function (ModuleName, FileName) {
        var TransactionURL = this.config.DeleteURL;
        var debug = this.config.Debug;
        $.ajax({
            type: 'POST',
            data: 'Parameter=' + ModuleName.toLowerCase() + '/' + FileName.toLowerCase(),
            // data: 'StoredProcedure=' + encodeURIComponent(Schema + '.' + StoredProcedure.trim()) +
            //     '&Parameter=' + encodeURIComponent(Parameter.trim()) +
            //     '&WithParameter=' + encodeURIComponent(WithParameter.toString().trim()) +
            //     '&ServerIncludeLibrary=' + encodeURIComponent(ServerIncludeLibrary.trim()) +
            //     '&ServerExecuteBefore=' + encodeURIComponent(ServerExecuteBefore.trim()) +
            //     '&ServerExecuteAfter=' + encodeURIComponent(ServerExecuteAfter.trim()),
            url: TransactionURL,
            async: true,
            success: function (Result) {
                if (debug === true) {
                    console.log('>>>> ', Result, 'Uploaded file: ' + FileName + ' was deleted successfully.');
                }
                // Results = Result;
                // switch (TransactionType) {
                //   case 1:
                //     events.publish (Publish,
                //         {
                //           Data: Result
                //         }
                //       );
                //     break;
                //   case 2:
                //     if (Async === true) {
                //       events.publish (Publish,
                //         {
                //           Data: options.Data,
                //           Response: Result[Tag][0],
                //           CurrentRow: options.Row
                //         }
                //       );
                //     }
                //     break;
                // }
            },
            error: function (err) {
                if (debug === true) {
                    console.log('>>>> ', err, 'An error encountered while trying to delete Uploaded file: ' + FileName + '.');
                }
                // if (debug === true) {
                //   console.log('>>>> err: ', err);
                // }
                // switch (TransactionType) {
                //   case 1:
                //     if (debug === true) {
                //       console.log('>>>> search failed!');
                //     }
                //     events.publish (Publish,
                //         {
                //           Data: null
                //         }
                //       );
                //     break;
                //   case 2:
                //     if ((debug === true) &&
                //         (Async === true)) {
                //       console.log ('>>>> transaction failed!');
                //     }
                //     break;
                // }
            }
        });
    };
    Global4Service.prototype.OpenAttachment = function (ModuleName, FileExt, FileName) {
        var newWindow;
        switch (FileExt) {
            case 'DOC':
            case 'DOCX':
            case 'XLS':
            case 'XLSX':
            case 'PPT':
            case 'PPTX':
                newWindow = window.open(this.DefaultURL() + ModuleName.toLowerCase() + '/' + FileName, '_blank');
                break;
            case 'JPG':
            case 'JPEG':
            case 'PNG':
            case 'GIF':
                newWindow = window.open('', FileName);
                newWindow.document.write('<img src="' + this.DefaultURL() + ModuleName.toLowerCase() + '/' + FileName + '" />'
                //  +
                // '<script type="text/jscript">' +
                //   'document.addEventListener("contextmenu", function (e) {' +
                //       'e.preventDefault();' +
                //     '}, false); ' +
                // '</script> '
                );
                break;
            case 'PDF':
                // newWindow = window.open(this.global.DefaultURL() + ModuleName.toLowerCase() + '/' + FileName + '#toolbar=0&navpanes=0', FileName);
                newWindow = window.open(this.DefaultURL() + ModuleName.toLowerCase() + '/' + FileName, FileName);
                break;
        }
    };
    Global4Service.ngInjectableDef = i0.defineInjectable({ factory: function Global4Service_Factory() { return new Global4Service(i0.inject(i1.ToastController), i0.inject(i1.AlertController), i0.inject(i1.LoadingController), i0.inject(i1.NavController), i0.inject(i2.Events), i0.inject(i3.Config), i0.inject(i4.Router)); }, token: Global4Service, providedIn: "root" });
    return Global4Service;
}());
export { Global4Service };
